import { IProduct, Product } from "../../Product";
import { mapData } from "@/utilities/DataMapping";

export interface IProductSearchResult {
    product: IProduct;
    productRangeDescription: string;
    productTypeDescription: string;
    productImageOriginalFilename: string;
    productPricingState: number;
    productPricingMessage: string;
    productSpecs: string;
}

export class ProductSearchResult implements IProductSearchResult {

    constructor(data?: IProductSearchResult) {
        if (data) this.update(data);
    }

    update(data: IProductSearchResult): void {
        mapData(data, { 
            root: () => this,
            product: () => new Product()
        });
    }

    product: Product = new Product();
    productRangeDescription: string = "";
    productTypeDescription: string = "";
    productImageOriginalFilename: string = "";
    productPricingState: number = 0;
    productPricingMessage: string = "";
    productSpecs: string = "";
}
