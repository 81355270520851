






























import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import { ISaveResponse } from "@/model/ISaveResponse";
import { ProductRange, IProductRange } from "@/model/ProductRange";
import { Ref } from "vue-property-decorator";
import { Retailer } from "@/model/Retailer";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class ProductRangeDialogue extends Vue {

    async mounted(): Promise<void> { 
        this.loadRetailers();
    }

    //
    // -- properties
    //

    @Ref("productRangeForm") private readonly productRangeForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private productRange: ProductRange = new ProductRange();
    private retailers: Array<Retailer> = [];

    //
    // -- methods
    //

    async loadRetailers(): Promise<void> {
        this.retailers = [];
        const response = await apiClient.get("/api/product/retailers");
        this.retailers.push(...response);
    }

    add(): void {
        this.reset();
        this.dialogueTitle = "Add Product Range";
        this.showDialogue = true;
    }

    async edit(id: string): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit Product Range";
        const productRangeData: IProductRange = await apiClient.get(`api/product/LoadProductRange?id=${id}`);
        this.productRange.update(productRangeData);
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.productRange);
        this.productRangeForm?.resetValidation();
    }

    private async save() {
        if (!this.productRangeForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/product/saveProductRange", this.productRange, "product-range-save");
        if (response.isSuccess){
            toastr.success("Saved");
            this.$emit("refresh");    
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
        
    }
}
