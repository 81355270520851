import { IProductGroup, ProductGroup } from "../../ProductGroup";
import { mapData } from "@/utilities/DataMapping";

export interface IProductGroupVM {
    productGroup: IProductGroup;
    groupDescription: string;
    isSelected: boolean;
}

export class ProductGroupVM implements IProductGroupVM {

    constructor(data?: IProductGroupVM) {
        if (data) this.update(data);
    }

    update(data: IProductGroupVM): void {
        mapData(data, { 
            root: () => this,
            productGroup: () => new ProductGroup()
        });
    }

    productGroup: ProductGroup = new ProductGroup();
    groupDescription: string = "";
    isSelected: boolean = false;
}
