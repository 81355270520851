import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IProductGroup {
    productID: string;
    groupID: string;

    guid: string;
}

export class ProductGroup implements IProductGroup {

    constructor(data?: IProductGroup) {
        if (data) this.update(data);
    }

    update(data: IProductGroup): void {
        mapData(data, { root: () => this });
    }

    productID: string = utils.emptyGuidValue;
    groupID: string = utils.emptyGuidValue;
    
    guid: string = utils.newGuid();
}
