



























import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import ApiButton from "@/vue/components/ApiButton.vue";
import DatePicker from "@/vue/components/DatePicker.vue"
import utils from "@/utilities/Utils";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { ProductPricing } from "@/model/ProductPricing";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        DatePicker
    } 
})

export default class ProductPricingDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("pricingForm") private readonly pricingForm!: VForm;  

    private showDialogue: boolean = false;
    private pricing: ProductPricing = new ProductPricing();

    //
    // -- methods
    //

    open(id: string): void {
        this.reset();
        this.pricing.productID = id;
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.pricing);
        this.pricingForm?.resetValidation();
    }

    private async save() {
        if (!this.pricingForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        if (this.pricing.rebatePer1000Units < 0.01) {
            this.pricing.rebatePer1000Units = 0;
        }

        const response: ISaveResponse = await apiClient.post("/api/product/saveProductPricing", this.pricing, "pricing-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");    
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
        
    }
}
