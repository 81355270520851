










































































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/dialogues/ConfirmDialogue.vue";
import fileDownload from "@/utilities/FileDownload";
import ImagePreviewDialogue from "@/vue/components/dialogues/ImagePreviewDialogue.vue";
import utils from "@/utilities/Utils";
import { Document, IDocument } from "@/model/Document";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Product } from "@/model/Product";
import { ProductGroupVM, IProductGroupVM } from "@/model/Api/VM/ProductGroupVM";
import { ProductRange } from "@/model/ProductRange";
import { ProductType } from "@/model/ProductType";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        ConfirmDialogue,
        ImagePreviewDialogue
    } 
})

export default class ProductDialogue extends Vue {

    async mounted(): Promise<void> { 
        this.loadTypes();
        this.loadRanges();
    }

    //
    // -- properties
    //

    @Ref("productForm") private readonly productForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private product: Product = new Product();
    private image: Document = new Document();
    private uploadDoc: File | null = null;
    private groups: Array<ProductGroupVM> = [];
    private types: Array<ProductType> = [];
    private ranges: Array<ProductRange> = [];

    selectedSizeOptionGroupID = utils.emptyGuidValue;
    private imageToDeleteID: string = utils.emptyGuidValue;

    //
    // -- validations
    //

    @Watch("product.isContract")
    private async validateIsContract() {
        if (this.product.isContract) {
            this.product.requiresNonContractSizing = false;
            this.product.userSpecifiedSizeOptions = false;
            this.product.requiresCoo = false;
            this.product.allowDuplicateProduct = false;
            this.loadGroups(this.product.id, true, false);
        } else {
            this.groups = [];
            this.selectedSizeOptionGroupID = utils.emptyGuidValue;
            this.product.requiresWashcare = false;
        }
    }

    @Watch("product.requiresNonContractSizing")
    private async validateRequiresNonContractSizing() {
        if (this.product.requiresNonContractSizing) {
            this.loadGroups(this.product.id, false, true);
        } else if (!this.product.requiresNonContractSizing && !this.product.isContract) {
            this.groups = [];
            this.selectedSizeOptionGroupID = utils.emptyGuidValue;
            this.product.userSpecifiedSizeOptions = false;
        }
    }

    @Watch("selectedSizeOptionGroupID")
    private async isSelectedSizeOptionGroupIDChanged() {
        if (this.selectedSizeOptionGroupID == utils.emptyGuidValue) {
            return;
        } else {
            this.groups.forEach(g => {
                if (g.productGroup.groupID == this.selectedSizeOptionGroupID) {
                    g.isSelected = true;
                } else {
                    g.isSelected = false;
                }
            });
        }
    }

    //
    // -- methods
    //

    add(): void {
        this.reset();
        this.dialogueTitle = "Add Product";
        this.showDialogue = true;
    }

    async edit(id: string): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit Product";
        this.loadProduct(id);

        this.showDialogue = true;
    }

    async loadProduct(id: string): Promise<void> {
        const responseData = await apiClient.get(`api/product/Load?id=${id}`);
        this.product.update(responseData.product);
        this.groups.push(...responseData.groups.map((g: IProductGroupVM) => new ProductGroupVM(g)));
        this.image.update(responseData.image);

        this.groups.forEach(g => {
            g.productGroup.guid = utils.newGuid();
        })
    }

    async loadTypes(): Promise<void> {
        this.types = [];
        const response = await apiClient.get("/api/product/productTypes");
        this.types.push(...response);
    }

    async loadRanges(): Promise<void> {
        this.ranges = [];
        const response = await apiClient.get("/api/product/productRanges");
        this.ranges.push(...response);
    }

    async loadGroups(id: string, contractGroups: boolean, stockGroups: boolean): Promise<void> {
        this.groups = [];
        const response = await apiClient.get(`/api/product/loadAvailableGroups?id=${id}&contractGroups=${contractGroups}&stockGroups=${stockGroups}`);
        this.groups.push(...response);

        if (!this.product.isContract && this.product.requiresNonContractSizing) {
            this.groups.forEach(g => {
                if (g.isSelected) {
                    this.selectedSizeOptionGroupID = g.productGroup.groupID;
                }
            });
        }
    }

    private reset() {
        this.selectedSizeOptionGroupID = utils.emptyGuidValue;
        utils.resetObject(this.product);
        this.uploadDoc = null;
        this.product.id = utils.emptyGuidValue;
        this.groups = [];
        this.productForm?.resetValidation();
    }

    private async downloadDocument(imageID: string) {
        if (imageID == utils.emptyGuidValue || imageID == null) {
            toastr.warning("Image is not available.");
        } else {
            await fileDownload.download(`/api/document/downloadDocument?id=${imageID}`, this.image.originalFilename);
        }
    }

    private async viewImage(imageID: string) {
        if (imageID == utils.emptyGuidValue || imageID == null) {
            toastr.warning("Image is not available.");
        } else {
            const dialog: ImagePreviewDialogue = this.$refs.imagePreviewDialogue as ImagePreviewDialogue;
            dialog.open(imageID);
        }
    }

    deleteDocument(imageID: string): void {
        this.imageToDeleteID = imageID;

        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async doImageDelete() {
        const response: ISaveResponse = await apiClient.get(`/api/document/documentDelete?id=${this.imageToDeleteID}`);
        if (response.isSuccess) {
            toastr.warning("Document deleted");
            this.product.imageID = '00000000-0000-0000-0000-000000000000';
            this.uploadDoc = null;
        } else {
            toastr.warning("Failed to delete document");
            return;
        }

        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.hide();
    }

    private async save() {
        const isValid = this.productForm.validate();

        if (!isValid) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        if (this.uploadDoc != null && this.uploadDoc.size > 0 && this.uploadDoc.size <= 20000000) {
            const uploadResponse: { success: boolean; document: IDocument } = await apiClient.uploadFile("api/document/uploadDocument", this.uploadDoc);
            if (!uploadResponse.success) {
                toastr.warning("Failed to upload image");
                return;
            } else {
                const response: ISaveResponse = await apiClient.post("/api/document/saveDocument", uploadResponse.document);
                if (response.isSuccess) {
                    toastr.success("Image uploaded successfully");
                    this.product.imageID = response.newID;
                } else {
                    toastr.warning("Failed to save image details");
                    return;
                }
            }
        } else if (this.uploadDoc != null && this.uploadDoc.size == 0 && this.uploadDoc.size > 20000000) {
            toastr.warning("Please check you have added an image to upload and it is under 20MB in size");
        }

        const postData = {
            product: this.product,
            groups: this.groups
        }

        const saveResponse: ISaveResponse = await apiClient.post("/api/product/saveProduct", postData, "product-save");
        if (saveResponse.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
    }
}
