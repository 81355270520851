import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IProductType {
    id: string;
    description: string;
    isHidden: boolean;
}

export class ProductType implements IProductType {

    constructor(data?: IProductType) {
        if (data) this.update(data);
    }

    update(data: IProductType): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    description: string = "";
    isHidden: boolean = false;
}
