

























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ProductTypeDialogue from "@/vue/components/dialogues/ProductTypeDialogue.vue";
import utils from "@/utilities/Utils";
import { ProductTypeVM, IProductTypeVM } from "@/model/Api/VM/ProductTypeVM";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { StateChanger } from "vue-infinite-loading";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        ProductTypeDialogue
    }
})

export default class ProductTypes extends Vue {
    
    //
    // -- properties
    //

    private productTypeHeaders = [
        { text: "Description", value: "productType.description", sortable: true },
        { text: "Product Count", value: "productCount", align: "center", sortable: true },
        { text: "Is Hidden", value: "productType.isHidden", align: "center", sortable: false }    
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new SearchParameters();
    private productTypeList: Array<ProductTypeVM> = [];

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.searchText")
    private onSearchTextChanged() {
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    addNew(): void {
        const dialog: ProductTypeDialogue = this.$refs.productTypeDialogue as ProductTypeDialogue;
        dialog.add();
    }

    edit(productType: IProductTypeVM): void {
        const dialog: ProductTypeDialogue = this.$refs.productTypeDialogue as ProductTypeDialogue;
        dialog.edit(productType.productType.id);
    }

    refreshSearch(): void {
        this.productTypeList = [];
        this.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/product/searchProductTypes", this.searchParameters);
        if (response.list.length) {
            this.productTypeList.push(...response.list.map((pt: IProductTypeVM) => new ProductTypeVM(pt)));
            this.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }
}
