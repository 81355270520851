import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IProductPricing {
    id: string;
    productID: string;
    sellPricePer1000Units: number;
    rebatePer1000Units: number;
    effectiveFromDate: Date;
    createdDate: Date;
    createdByUserID: string;
}

export class ProductPricing implements IProductPricing {

    constructor(data?: IProductPricing) {
        if (data) this.update(data);
    }

    update(data: IProductPricing): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    productID: string = utils.emptyGuidValue;
    sellPricePer1000Units: number = 0;
    rebatePer1000Units: number = 0;
    effectiveFromDate: Date = new Date(utils.emptyDateValue);
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID = utils.emptyGuidValue;
}