










































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import fileDownload from "@/utilities/FileDownload";
import ImagePreviewDialogue from "@/vue/components/dialogues/ImagePreviewDialogue.vue";
import ProductDialogue from "@/vue/components/dialogues/ProductDialogue.vue";
import ProductPricingHistoryDialogue from "@/vue/components/dialogues/ProductPricingHistoryDialogue.vue";
import utils from "@/utilities/Utils";
import { ProductSearchResult, IProductSearchResult } from "@/model/Api/SearchResults/ProductSearchResult";
import { ProductRange } from "@/model/ProductRange";
import { ProductType } from "@/model/ProductType";
import { ProductSearchParameters } from "@/model/Api/SearchParams/ProductSearchParameters";
import { StateChanger } from "vue-infinite-loading";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        ImagePreviewDialogue,
        ProductDialogue,
        ProductPricingHistoryDialogue
    }
})

export default class ProductsSearchPage extends Vue {

    async mounted(): Promise<void> { 
        this.loadTypes();
        this.loadRanges();
    }
    
    //
    // -- properties
    //

    private productHeaders = [
        { text: "Range", value: "productRangeDescription",  sortable: true },
        { text: "Type", value: "productTypeDescription", sortable: true },
        { text: "Ref", value: "product.ref", sortable: true },
        { text: "Description", value: "product.description", sortable: true },
        { text: "Product Specs", value: "productSpecs", align: "center", sortable: false },
        { text: "", value: "product.allowDuplicateProduct", align: "center", sortable: false },
        { text: "Requires Washcare", value: "product.requiresWashcare", align: "center", sortable: false },
        { text: "Image", value: "product.imageID", align: "center", sortable: false },
        { text: "Pricing", value: "productPricingState", align: "center", sortable: false },
        { text: "Is Hidden?", value: "product.isHidden", align: "center", sortable: false }
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new ProductSearchParameters();
    private productList: Array<ProductSearchResult> = [];
    private types: Array<ProductType> = [];
    private ranges: Array<ProductRange> = [];

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.searchParameters.searchText")
    private onSearchTextChanged() {
        this.debouncedRefreshSearch();
    }

    @Watch("searchParameters.typeID")
    private onTypeIDChanged() {
        if (this.searchParameters.typeID == undefined) {
            this.searchParameters.typeID = utils.emptyGuidValue;
        }
        this.debouncedRefreshSearch();
    }

    @Watch("searchParameters.rangeID")
    private onRangeIDChanged() {
        if (this.searchParameters.rangeID == undefined) {
            this.searchParameters.rangeID = utils.emptyGuidValue;
        }
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    async loadTypes(): Promise<void> {
        this.types = [];
        const response = await apiClient.get("/api/product/productTypes");
        this.types.push(...response);
    }

    async loadRanges(): Promise<void> {
        this.ranges = [];
        const response = await apiClient.get("/api/product/productRanges");
        this.ranges.push(...response);
    }

    addNew(): void {
        const dialog: ProductDialogue = this.$refs.productDialogue as ProductDialogue;
        dialog.add();
    }

    edit(product: IProductSearchResult): void {
        const dialog: ProductDialogue = this.$refs.productDialogue as ProductDialogue;
        dialog.edit(product.product.id);
    }

    refreshSearch(): void {
        this.productList = [];
        this.searchParameters.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    private async downloadDocument(imageID: string, filename: string) {
        if (imageID == utils.emptyGuidValue || imageID == null) {
            toastr.warning("Image is not available.");
        } else {
            await fileDownload.download(`/api/document/downloadDocument?id=${imageID}`, filename);
        }
    }

    async viewProductImage(imageID: string): Promise<void> {
        if (imageID == utils.emptyGuidValue || imageID == null) {
            toastr.warning("Image is not available.");
        } else {
            const dialog: ImagePreviewDialogue = this.$refs.imagePreviewDialogue as ImagePreviewDialogue;
            dialog.open(imageID);
        }
    }

    async loadProductPricing(id: string, description: string): Promise<void> {
        const dialog: ProductPricingHistoryDialogue = this.$refs.productPricingHistoryDialogue as ProductPricingHistoryDialogue;
        dialog.open(id, description);
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/product/searchProducts", this.searchParameters);
        if (response.list.length) {
            this.productList.push(...response.list.map((p: IProductSearchResult) => new ProductSearchResult(p)));
            this.searchParameters.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        } else {
            stateChanger.complete();
        }         
    }
}
