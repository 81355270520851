import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IProductRange {
    id: string;
    retailerID: string;
    description: string;
}

export class ProductRange implements IProductRange {

    constructor(data?: IProductRange) {
        if (data) this.update(data);
    }

    update(data: IProductRange): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    retailerID: string = utils.emptyGuidValue;
    description: string = "";
}
