































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import ProductsSearchPage from "@/vue/components/productPages/ProductsSearchPage.vue";
import ProductTypes from "@/vue/components/productPages/ProductTypes.vue";
import ProductRanges from "@/vue/components/productPages/ProductRanges.vue";

@Component({
    components: { 
        ApiButton,
        ProductsSearchPage,
        ProductTypes,
        ProductRanges
    }
})

export default class Products extends Vue {


}

