






























import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { VForm } from "@/vForm";
import * as toastr from "toastr";
import ApiButton from "@/vue/components/ApiButton.vue";
import { ISaveResponse } from "@/model/ISaveResponse";
import { ProductType, IProductType } from "@/model/ProductType";

@Component({ components: { ApiButton } })

export default class ProductTypeDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("productTypeForm") private readonly productTypeForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private productType: ProductType = new ProductType();

    //
    // -- methods
    //

    add(): void {
        this.reset();
        this.dialogueTitle = "Add Product Type";
        this.showDialogue = true;
    }

    async edit(id: string): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit Product Type";
        const productTypeData: IProductType = await apiClient.get(`api/product/LoadProductType?id=${id}`);
        this.productType.update(productTypeData);
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.productType);
        this.productTypeForm?.resetValidation();
    }

    private async save() {
        if (!this.productTypeForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }
        const response: ISaveResponse = await apiClient.post("/api/product/saveProductType", this.productType, "product-type-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");    
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
    }
}
