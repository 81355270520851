import { IProductRange, ProductRange } from "../../ProductRange";
import { mapData } from "@/utilities/DataMapping";

export interface IProductRangeVM {
    productRange: IProductRange;
    retailer: string;
    productCount: number;
}

export class ProductRangeVM implements IProductRangeVM {

    constructor(data?: IProductRangeVM) {
        if (data) this.update(data);
    }

    update(data: IProductRangeVM): void {
        mapData(data, { 
            root: () => this,
            productRange: () => new ProductRange()
        });
    }

    productRange: ProductRange = new ProductRange();
    retailer: string = "";
    productCount: number = 0;
}
