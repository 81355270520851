var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3 mb-2",attrs:{"label":"Search","append-icon":"mdi-magnify","clearable":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchParameters.searchParameters.searchText),callback:function ($$v) {_vm.$set(_vm.searchParameters.searchParameters, "searchText", $$v)},expression:"searchParameters.searchParameters.searchText"}}),_c('v-select',{staticClass:"mr-3 mb-3",attrs:{"label":"Type","items":_vm.types,"item-text":"description","item-value":"id","clearable":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.searchParameters.typeID),callback:function ($$v) {_vm.$set(_vm.searchParameters, "typeID", $$v)},expression:"searchParameters.typeID"}}),_c('v-select',{staticClass:"mr-3 mb-3",attrs:{"label":"Range","items":_vm.ranges,"item-text":"description","item-value":"id","clearable":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.searchParameters.rangeID),callback:function ($$v) {_vm.$set(_vm.searchParameters, "rangeID", $$v)},expression:"searchParameters.rangeID"}}),_c('api-button',{staticClass:"mb-2",on:{"click":_vm.addNew}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Add new")],1)],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.productHeaders,"items":_vm.productList,"disable-pagination":"","hide-default-footer":"","dense":""},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.product.allowDuplicateProduct",fn:function(ref){
var item = ref.item;
return [(item.product.userSpecifiedSizeOptions || item.product.allowDuplicateProduct)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#13b1dd"}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[(item.product.userSpecifiedSizeOptions)?_c('span',[_vm._v("User Specified Size Options")]):_vm._e(),(item.product.userSpecifiedSizeOptions)?_c('br'):_vm._e(),(item.product.allowDuplicateProduct)?_c('span',[_vm._v("Allow Duplicate Product")]):_vm._e()]):_vm._e()]}},{key:"item.product.requiresWashcare",fn:function(ref){
var item = ref.item;
return [(item.product.requiresWashcare)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),(!item.product.requiresWashcare)?_c('v-icon',[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.product.imageID",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":item.product.imageID == '00000000-0000-0000-0000-000000000000',"color":"#13b1dd"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.viewProductImage(item.product.imageID)}}},[_vm._v("mdi-image-area")])]}},{key:"item.productPricingState",fn:function(ref){
var item = ref.item;
return [(item.productPricingState == 1)?_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.loadProductPricing(item.product.id, item.product.description)}}},[_vm._v("mdi-cash")]):_vm._e(),(item.productPricingState == 2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"amber"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.loadProductPricing(item.product.id, item.product.description)}}},'v-icon',attrs,false),on),[_vm._v("mdi-cash")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.productPricingMessage))])]):_vm._e(),(item.productPricingState == 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.loadProductPricing(item.product.id, item.product.description)}}},'v-icon',attrs,false),on),[_vm._v("mdi-cash")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.productPricingMessage))])]):_vm._e()]}},{key:"item.product.isHidden",fn:function(ref){
var item = ref.item;
return [(item.product.isHidden)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),(!item.product.isHidden)?_c('v-icon',[_vm._v("mdi-close")]):_vm._e()]}}],null,true)}),_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteLoadingHandler}}),_c('image-preview-dialogue',{ref:"imagePreviewDialogue"}),_c('product-dialogue',{ref:"productDialogue",on:{"refresh":_vm.refreshSearch}}),_c('product-pricing-history-dialogue',{ref:"productPricingHistoryDialogue",on:{"refresh":_vm.refreshSearch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }