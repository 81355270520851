





































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ProductPricingDialogue from "@/vue/components/dialogues/ProductPricingDialogue.vue";
import { ProductPricing } from "@/model/ProductPricing";

@Component({ components: { 
        ApiButton,
        ProductPricingDialogue
    } 
})

export default class ProductPricingHistoryDialogue extends Vue {

    //
    // -- properties
    //

    private pricingHeaders = [
        { text: "Sell Price per 1000 Units", value: "sellPricePer1000Units",  sortable: true },
        { text: "Rebate per 1000 Units", value: "rebatePer1000Units",  sortable: true },
        { text: "Effective From Date", value: "effectiveFromDate",  sortable: true }
    ];

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private productID: string = "";
    private productPrices: Array<ProductPricing> = [];

    //
    // -- methods
    //

    async open(id: string, product: string): Promise<void> {
        this.reset();
        this.dialogueTitle = product + " Pricing History";
        this.productID = id;
        await this.loadPricingHistory();
        this.showDialogue = true;
    }

    async loadPricingHistory(): Promise<void> {
        this.productPrices = [];
        const response = await apiClient.get(`/api/product/productPrices?id=${this.productID}`);
        this.productPrices.push(...response);
    }

    async addNew(): Promise<void> {
        const dialog: ProductPricingDialogue = this.$refs.productPricingDialogue as ProductPricingDialogue;
        dialog.open(this.productID);
    }

    private reset() {
        this.productID = "";
        this.dialogueTitle = "";
        this.productPrices = [];
    }

    refresh(): void {
        this.loadPricingHistory();
        this.$emit("refresh");  
    }
}
