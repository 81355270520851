import { IProductType, ProductType } from "../../ProductType";
import { mapData } from "@/utilities/DataMapping";

export interface IProductTypeVM {
    productType: IProductType;
    productCount: number;
}

export class ProductTypeVM implements IProductTypeVM {

    constructor(data?: IProductTypeVM) {
        if (data) this.update(data);
    }

    update(data: IProductTypeVM): void {
        mapData(data, { 
            root: () => this,
            productType: () => new ProductType()
        });
    }

    productType: ProductType = new ProductType();
    productCount: number = 0;
}
