





















import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ProductRangeDialogue from "@/vue/components/dialogues/ProductRangeDialogue.vue";
import utils from "@/utilities/Utils";
import { ProductRangeVM, IProductRangeVM } from "@/model/Api/VM/ProductRangeVM";
import { Retailer } from "@/model/Retailer";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { StateChanger } from "vue-infinite-loading";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        ProductRangeDialogue
    }
})

export default class ProductRanges extends Vue {

    async mounted(): Promise<void> { 
        this.loadRetailers();
    }
    
    //
    // -- properties
    //

    private productRangeHeaders = [
        { text: "Description", value: "productRange.description", sortable: true },
        { text: "Retailer", value: "retailer", sortable: true },
        { text: "Product Count", value: "productCount", align: "center", sortable: true } 
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new SearchParameters();
    private productRangeList: Array<ProductRangeVM> = [];
    private retailers: Array<Retailer> = [];

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.searchText")
    private onSearchTextChanged() {
        this.debouncedRefreshSearch();
    }

    @Watch("searchParameters.id")
    private onIDChanged() {
        if (this.searchParameters.id == undefined) {
            this.searchParameters.id = utils.emptyGuidValue;
        }
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    async loadRetailers(): Promise<void> {
        this.retailers = [];
        const response = await apiClient.get("/api/product/retailers");
        this.retailers.push(...response);
    }

    addNew(): void {
        const dialog: ProductRangeDialogue = this.$refs.productRangeDialogue as ProductRangeDialogue;
        dialog.add();
    }

    edit(productRange: IProductRangeVM): void {
        const dialog: ProductRangeDialogue = this.$refs.productRangeDialogue as ProductRangeDialogue;
        dialog.edit(productRange.productRange.id);
    }

    refreshSearch(): void {
        this.productRangeList = [];
        this.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/product/searchProductRanges", this.searchParameters);
        if (response.list.length) {
            this.productRangeList.push(...response.list.map((pr: IProductRangeVM) => new ProductRangeVM(pr)));
            this.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }
}
